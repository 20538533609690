export const UppercaseFirstLetter = (str: string) => {
  return (str || "").charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const SplitOnUnderscore = (str: string) => {
  return (str || "").split("_").join(" ");
};

export const SplitAndCapitalize = (str: string) => {
  return (str || "")
    .split("_")
    .map((word) => UppercaseFirstLetter(word))
    .join(" ");
};

export const SplitPascal = (str: string) => {
  return (str || "")
    .split(/(?=[A-Z])/)
    .map((word) => UppercaseFirstLetter(word))
    .join(" ");
};

export const URL_REG_EXP =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/\S*)?$/;

export async function HashObject(obj: Record<string, any>): Promise<string> {
  const str = JSON.stringify(obj, Object.keys(obj).sort());
  const buffer = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

export enum SORT_ORDER {
  ASC = "asc",
  DESC = "desc",
}

export const GetTheme = () => {
  if (typeof window === "undefined") {
    return "light";
  }

  let theme = window.localStorage && window.localStorage.getItem("theme");

  if (theme === "dark" || theme === "light") {
    return theme;
  } else if (theme === "auto") {
    return window?.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  }

  return "light";
};

export const GetActualTheme = () => {
  if (typeof window === "undefined") {
    return "light";
  }

  let theme = window.localStorage && window.localStorage.getItem("theme");

  if (theme === "dark" || theme === "light" || theme === "auto") {
    return theme;
  }

  return "light";
};
