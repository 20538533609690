import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../library/Icon";

type StatProps = {
  title: string;
  stats:
    | {
        inbound: string;
        outbound: string;
      }
    | null
    | undefined;
};

const StatCompDirectionCard: React.FC<StatProps> = ({ title, stats }) => {
  const { t } = useTranslation("stats");
  const { t: cat } = useTranslation("calls");
  const { t: ct } = useTranslation("common");

  const LOADING_SKELETON = (
    <div
      className={clsx("animate-pulse", "bg-slate-500/30", "rounded", "min-h-3")}
    />
  );

  const hasInbound =
    stats &&
    (Number.isNaN(Number(stats?.inbound)) || Number(stats?.inbound) > 0);
  const hasOutbound =
    stats &&
    (Number.isNaN(Number(stats?.outbound)) || Number(stats?.outbound) > 0);

  return (
    <div
      className={clsx(
        "p-4",
        "rounded-xl",
        "shadow-md",
        "bg-panel",
        "grid",
        "gap-2",
        "h-full"
      )}
    >
      <div>
        <h2 className={clsx("mb-2")}>{title}</h2>
        <hr />
      </div>
      <div className={clsx("grid", "gap-2")}>
        {stats === null && <div>{t("noStatsAvailable")}</div>}
        {stats === undefined && (
          <>
            {LOADING_SKELETON}
            {LOADING_SKELETON}
            {LOADING_SKELETON}
            {LOADING_SKELETON}
          </>
        )}
        {stats && !hasInbound && !hasOutbound && <div>{t("noStats")}</div>}
        {stats && (hasInbound || hasOutbound) && (
          <>
            <div>
              <div className="text-600 text-sm">{cat("inbound")}</div>
              <div>
                <Icon icon={"phone-arrow-down-left"} className="mr-2" />
                {hasInbound ? stats.inbound : ct("na")}
              </div>
            </div>
            <div>
              <div className="text-600 text-sm">{cat("outbound")}</div>
              <div>
                <Icon icon={"phone-arrow-up-right"} className="mr-2" />
                {hasOutbound ? stats.outbound : ct("na")}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StatCompDirectionCard;
