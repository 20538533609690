import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { PieDataType } from "@/utils/common/charts";
import { GetTheme } from "@/utils/common/general";
import { FormatNumber } from "@/utils/common/generic";
import { SmartT } from "@/utils/common/translations";
import { ResponsivePie } from "@nivo/pie";

type PieProps = {
  data: PieDataType[];
  arcLabel?: (e: any) => string;
  themeOverride?: "light" | "dark" | "auto";
};

export const Pie = ({ data, arcLabel, themeOverride }: PieProps) => {
  const { t } = useTranslation("stats");
  const { t: ct } = useTranslation("common");

  const theme = themeOverride || GetTheme();
  const dataFiltered = data.filter((d) => d.value > 0);

  return (
    <ResponsivePie
      data={dataFiltered}
      margin={{ top: 30, right: 60, bottom: 30, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      tooltip={(d: any) => (
        <div
          className={clsx(
            "p-2",
            "bg-panel",
            "shadow-lg",
            "rounded-lg",
            "border"
          )}
        >
          {SmartT(d.datum?.id as string, t, {
            value: FormatNumber(d.datum?.value, ct, 2),
          })}
        </div>
      )}
      sortByValue={true}
      arcLabel={arcLabel || undefined}
      arcLinkLabel="label"
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={theme === "light" ? "#333333" : "#ffffff"}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLinkLabelsDiagonalLength={10}
      arcLinkLabelsStraightLength={5}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      colors={({ id, data }) => data?.color || "hsl(0, 0%, 0%)"}
    />
  );
};
