import clsx from "clsx";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Bar } from "@/components/common/charts/Bar";
import FancyLoader from "@/components/common/fancyLoader";
import Button from "@/components/library/Button";
import { CallByDayStat } from "@/types/admin/dashboard";
import { BarDataKeyedType } from "@/utils/common/charts";
import {
  FormatNumber,
  GetDate_ISO,
  GetDayShortName,
} from "@/utils/common/generic";
import { GetColors } from "@/utils/library/color";
import { Icon } from "../library/Icon";

type StatCompProps = {
  callsByDay: CallByDayStat[] | null | undefined;
};

const StatCompDailyCalls: React.FC<StatCompProps> = ({ callsByDay }) => {
  const { t } = useTranslation("stats");
  const { t: ct } = useTranslation("common");

  const [data, setData] = React.useState<
    BarDataKeyedType<
      "created_at",
      | "thisWeekInbound"
      | "lastWeekInbound"
      | "thisWeekOutbound"
      | "lastWeekOutbound"
    >[]
  >([]);
  const [direction, setDirection] = React.useState<
    "inbound" | "outbound" | "both"
  >("both");
  const [week, setWeek] = React.useState<"thisWeek" | "lastWeek" | "both">(
    "both"
  );
  const [keys, setKeys] = React.useState<string[]>([
    "thisWeekInbound",
    "thisWeekOutbound",
    "lastWeekInbound",
    "lastWeekOutbound",
  ]);

  useEffect(() => {
    if (!callsByDay) {
      setData([]);
      return;
    }

    const colors = callsByDay ? GetColors(4) : [];

    //Starting today and working backwards we want a total of 14 days (7 rows)
    const barData: BarDataKeyedType<
      "created_at",
      | "thisWeekInbound"
      | "lastWeekInbound"
      | "thisWeekOutbound"
      | "lastWeekOutbound"
    >[] = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() - 6 + i);

      const dateString = GetDate_ISO(date);
      const dayName = GetDayShortName(ct, date);

      const lastWeek = new Date();
      lastWeek.setDate(lastWeek.getDate() - 13 + i);
      const lastWeekDateString = GetDate_ISO(lastWeek);

      const inboundStat = callsByDay.find(
        (stat) =>
          stat.direction === "inbound" && stat.created_at.includes(dateString)
      );
      const outboundStat = callsByDay.find(
        (stat) =>
          stat.direction === "outbound" && stat.created_at.includes(dateString)
      );

      const inboundLastWeekStat = callsByDay.find(
        (stat) =>
          stat.direction === "inbound" &&
          stat.created_at.includes(lastWeekDateString)
      );
      const outboundLastWeekStat = callsByDay.find(
        (stat) =>
          stat.direction === "outbound" &&
          stat.created_at.includes(lastWeekDateString)
      );

      barData.push({
        created_at: dayName,
        thisWeekInbound: inboundStat?.count || 0,
        thisWeekInboundColor: colors[1],
        lastWeekInbound: inboundLastWeekStat?.count || 0,
        lastWeekInboundColor: colors[3],
        thisWeekOutbound: outboundStat?.count || 0,
        thisWeekOutboundColor: colors[0],
        lastWeekOutbound: outboundLastWeekStat?.count || 0,
        lastWeekOutboundColor: colors[2],
      });
    }

    setData(barData);
  }, [callsByDay]);

  useEffect(() => {
    if (!callsByDay) {
      return;
    }

    const keys: string[] = [];

    if (week === "thisWeek" || week === "both") {
      if (direction === "inbound" || direction === "both") {
        keys.push("thisWeekInbound");
      }

      if (direction === "outbound" || direction === "both") {
        keys.push("thisWeekOutbound");
      }
    }

    if (week === "lastWeek" || week === "both") {
      if (direction === "inbound" || direction === "both") {
        keys.push("lastWeekInbound");
      }

      if (direction === "outbound" || direction === "both") {
        keys.push("lastWeekOutbound");
      }
    }

    setKeys(keys);
  }, [week, direction]);

  return (
    <div
      className={clsx(
        "bg-panel",
        "p-4",
        "rounded-xl",
        "shadow-lg",
        "relative",
        "h-full"
      )}
    >
      <div className={clsx("grid", "gap-2", "grid-cols-[1fr_80px]", "mb-2")}>
        <h1 className="text-xl">{t("callsLast7Days")}</h1>
      </div>
      <hr />
      {callsByDay === null && (
        <div className="mt-2">{t("errorLoadingStats")}</div>
      )}
      {callsByDay === undefined && (
        <div className="text-center mt-8">
          <FancyLoader />
        </div>
      )}
      {callsByDay && callsByDay.length === 0 && (
        <div className="mt-8 text-center">{t("noStats")}</div>
      )}
      {callsByDay && callsByDay.length > 0 && (
        <>
          <div className={clsx("mt-2", "h-full")}>
            <div
              className={clsx(
                "h-[calc(100%-9rem)]",
                "lg:h-[calc(100%-5.5rem)]",
                "flex",
                "justify-center",
                "items-center"
              )}
            >
              <Bar
                data={data}
                keys={keys}
                indexBy="created_at"
                groupMode="grouped"
                label={(d) => (d.value > 0 ? FormatNumber(d.value, ct, 0) : "")}
                tooltip={(d) => (
                  <div
                    className={clsx(
                      "p-2",
                      "bg-panel",
                      "shadow-lg",
                      "rounded-lg",
                      "border"
                    )}
                  >
                    {t(d.id as string, {
                      value: FormatNumber(d.value, ct, 2),
                    })}
                  </div>
                )}
              />
            </div>
            <div
              className={clsx("grid", "gap-2", "grid-cols-2", "lg:grid-cols-4")}
            >
              <Button
                type={
                  week === "both" || week === "thisWeek"
                    ? "secondary"
                    : "tertiary"
                }
                onClick={() => {
                  if (week === "lastWeek") {
                    setWeek("both");
                  } else {
                    setWeek("lastWeek");
                  }
                }}
              >
                <Icon icon="calendar" className="mr-2" />
                {t("thisWeek")}
              </Button>
              <Button
                type={
                  week === "both" || week === "lastWeek"
                    ? "secondary"
                    : "tertiary"
                }
                onClick={() => {
                  if (week === "thisWeek") {
                    setWeek("both");
                  } else {
                    setWeek("thisWeek");
                  }
                }}
              >
                <Icon icon="calendar-clock" className="mr-2" />
                {t("lastWeek")}
              </Button>
              <Button
                type={
                  direction === "both" || direction === "inbound"
                    ? "secondary"
                    : "tertiary"
                }
                onClick={() => {
                  if (direction === "outbound") {
                    setDirection("both");
                  } else {
                    setDirection("outbound");
                  }
                }}
              >
                <Icon icon="phone-arrow-down-left" className="mr-2" />
                {t("inbound").split("-")[0].trim()}
              </Button>
              <Button
                type={
                  direction === "both" || direction === "outbound"
                    ? "secondary"
                    : "tertiary"
                }
                onClick={() => {
                  if (direction === "inbound") {
                    setDirection("both");
                  } else {
                    setDirection("inbound");
                  }
                }}
              >
                <Icon icon="phone-arrow-up-right" className="mr-2" />
                {t("outbound").split("-")[0].trim()}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StatCompDailyCalls;
